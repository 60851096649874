import React from 'react'
import Layout from '../layouts'
import { graphql } from 'gatsby'
// import ShopCategory from "../components/ecwid/category"

const PrivacyPolicy = ({ data }) => (
	<Layout
		pathname="/privacy-policy/"
		head={{
			name: 'Privacy Policy',
			meta: {
				description:
					'Privacy Policy To keep it short and simple - We will not share your personal and/or financial information with anyone, ever.',
			},
		}}
	>
		<div className="container py-5">
			<div className="row">
				{/*<div className="col-md-4">
							<h2 className="section-heading">Categories</h2>
							<ShopCategory/>
						</div>*/}
				<div className="col-md-8 shop-items mx-auto">
					<div className="page-content">
						<h4 className="section-heading page-mt">Privacy Policy</h4>
						<h5>To keep it short and simple:</h5>
						<p>
							We will not share your personal and/or financial information with
							anyone, ever.
						</p>
						<p>
							Any personal and/or financial info about you that ends up on a
							piece of paper in our office gets shredded as soon as your order
							is processed.
						</p>
						<p>
							You won&#39;t ever get unsolicited (i.e., junk) email from us.
						</p>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default PrivacyPolicy

export const categorynodeQuery = graphql`
	query {
		allStrapiCategories(sort: { fields: id, order: ASC }) {
			edges {
				node {
					id
					name
					url
					subcategories {
						name
						url
					}
				}
			}
		}
	}
`
